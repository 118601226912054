import React,{useState} from 'react';
import { Container, Row, Col } from 'reactstrap';
import Link from 'next/link';
import Image from 'next/image';
import axios from 'axios';
import FlashState from "../../components/Common/FlashState";
import Footerlogo from '../../public/public/footer-logo.png';
import {Subscriber} from '../../lib/Endpoints';

function Footer() {
	const [email, setSubscriberEmail] = useState();
	const [getError, setError] = useState();

	const submitSubscriberForm = () => {
		if(email) {
			let formdata = { subscriber_email: email }
			axios.post(Subscriber, formdata, { headers: { "Accept": "application/json" }

			}).then((response) => {
				this.setState({ subscriber_email: ''})
				FlashState.set('message', response.data.message);
				FlashState.set('type', 'success');
				window.location.reload();

			},(error) => {
				if(error.response.status === 412) {
					setError(error.response.data.validation_error.subscriber_email);
				
				} else {
					setError(error.response.data.message);
				}

				setTimeout(() => { setError('') }, 5000);
			});
		}
	}

	return (
		<>
		<div className="public__footer">
			   <Container fluid="xl">
			<div className="footer_menu_section padding-wrap">
				<Row>
					<Col lg="3" sm="6">
					<h4 className="menu_heading_f">Quick Links</h4>
					<ul className="menu_f_listing">
						<li key="13"><a href={process.env.NEXT_PUBLIC_APP_URL + "/categories/"}>Browse Categories</a></li>
						<li key="2"><a href={process.env.NEXT_PUBLIC_APP_URL + "/write-review/"}>Write A Review</a></li>
						<li key="3"><a href={process.env.NEXT_PUBLIC_APP_URL + "/awards/"}>Awards</a></li>
						<li key="4"><a href={process.env.NEXT_PUBLIC_APP_URL + "/blogs/"}>Blog</a></li>
						<li key="5"><a href={process.env.NEXT_PUBLIC_APP_URL + "/review-guidelines/"}>Review Guidelines</a></li>
						<li key="6"><a href={process.env.NEXT_PUBLIC_APP_URL + "/agency/signup/"}>Sign Up</a></li>
					</ul>
					</Col>
					<Col lg="3" sm="6">
					<h4 className="menu_heading_f">Professionals</h4>
					<ul className="menu_f_listing">
						<li key="6"><a href={process.env.NEXT_PUBLIC_APP_URL + "/brand-builder/"}>Brand Builder</a></li>
						<li key="123"><a href={process.env.NEXT_PUBLIC_APP_URL + "/toprow-faq/"}>FAQ</a></li>
						<li key="8"><a href={process.env.NEXT_PUBLIC_APP_URL + "/terms-for-professional/"}>Terms for Professionals</a></li>
						<li key="9"><a href={process.env.NEXT_PUBLIC_APP_URL + '/pro-centre/'}>Pro Centre</a></li>
						<li key="12"><a href={process.env.NEXT_PUBLIC_APP_URL + "/press-and-annoucement/"}>Press & Announcements</a></li>
					</ul>
					</Col>
					<Col lg="3" sm="6">
					<h4 className="menu_heading_f">Toprow</h4>
					<ul className="menu_f_listing">
						<li key="10"><a href={process.env.NEXT_PUBLIC_APP_URL + "/about/"}>About</a></li>
						<li key="11"><a href={process.env.NEXT_PUBLIC_APP_URL + "/our-team/"}>Our Team</a></li>
						<li key="13"><a href={process.env.NEXT_PUBLIC_APP_URL + "/contact-us/"}>Contact Us</a></li>
						<li key="14"><a href={process.env.NEXT_PUBLIC_APP_URL + "/term-of-use/"}>Terms of Use</a></li>
						<li key="15"><a href={process.env.NEXT_PUBLIC_APP_URL + "/privacy/"}>Privacy</a></li>
						<li key="15"><a href={process.env.NEXT_PUBLIC_APP_URL + "/sitemap/"}>Sitemap</a></li>

					</ul>
					</Col>
					<Col lg="3" sm="6">
					<h4 className="menu_heading_f">Newsletters</h4>
					<div className="newsletter_f_wrapper">
							<input className='input_text form-control'
								name='subscriber_email'
								value={email}
								onChange={(e) => (setSubscriberEmail(e.target.value))}
								placeholder='Enter your email' />
									<button className="primary-btn" onClick={submitSubscriberForm}>
										<i className="fas fa-paper-plane"></i>
									</button>
							<span className="text-danger">{getError}</span>
					</div>             
					<p className="footer-p">Subscribe to our monthly Newsletters</p>
					</Col>
				</Row>
			</div>
			<div className="footer_bottom_section">
				<div className="logo-f-block block_bottom">
					<a href={process.env.NEXT_PUBLIC_APP_URL + "/"}><Image src={Footerlogo} alt="logo" /></a>
				 </div>
				<div className="social-f-block block_bottom">
					<ul className="social-links">
						<li key="16"><Link href="https://www.facebook.com/toprowinc"><a><i className="fab fa-facebook-f"></i></a ></Link></li>
						<li key="17"><Link href="https://twitter.com/toprowinc"><a><i className="fab fa-twitter"></i></a ></Link></li>
						<li key="18"><Link href="https://www.instagram.com/toprowofficial/"><a><i className="fab fa-instagram"></i></a ></Link></li>
						<li key="19"><Link href="https://in.pinterest.com/toprowinc"><a><i className="fab fa-pinterest-p"></i></a ></Link></li>
						<li key="20"><Link href="https://www.linkedin.com/in/top-row-44050123a/"><a><i className="fab fa-linkedin-in"></i></a ></Link></li>
					</ul>
				</div>
				<div className="text-right copyright-block block_bottom">
					<p className="footer-p">Copyright &copy; {new Date().getFullYear()} Toprow. All rights reserved.</p>
				</div>
			</div>
			</Container>
		</div>
		<Link href="#__next">
			<a className='back_to_top'><i class="fa fa-arrow-up"></i></a>
		</Link>
		</>
	); 
}
  
export default Footer;