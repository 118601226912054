import React,{useState, useEffect} from 'react';
import { Container } from "reactstrap";
import Link from 'next/link';
import Publiclogo from '../../public/public/public-logo.png';
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
import Head from 'next/head';
import Image from 'next/image';
import { getCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import axios from 'axios';

function Home() {
	const [dashboard, setDashboard] = useState('/admin/dashboard');
	const userRole =  getCookie('user_role');
	const [token, setToken] = useState('');
	const router = useRouter();
	const [user, setUser] = useState('');
	const [isRequestCall, setIsRequestCall] = useState(false);
	const [planExpire, setPlanExpire] = useState('');
	const [isProPlan, setIsProPlan] = useState('');
	const [navExpended, setNavExpanded] = useState(false);

	useEffect(() => {
		const token =  getCookie('token');

		let dashboard = '/admin/dashboard';
		if(token && userRole &&  userRole == 4) {
		   dashboard = '/users/dashboard';
		}

		if(token && userRole && userRole == 2) {
		   dashboard = '/agency/dashboard';
		}

		setDashboard(dashboard);
		setToken(token);

	}, [userRole]);

	const onChangeLinkExpand = (path) =>  {
		setNavExpanded(false);
        router.push(path)
	}

    const onChangeNavExpanded = (expanded) =>  {
		setNavExpanded(expanded);
	}


	const onScroll = () => {
		const scrollY = document.documentElement.scrollTop
		if (scrollY > 1500) {
			document.body.className =  'scroll_active';
		} else {
			document.body.className =  '';
		}
	}

	useEffect(() => {
		document.addEventListener('scroll', onScroll);
		let token = getCookie('token');
		if(token && !user) {
			try {
				const base64 = require('base-64');
				axios.get(process.env.NEXT_PUBLIC_API_URL+'user', { headers: {
					"Authorization" : 'Bearer '+ base64.decode(token),
					"Accept": "application/json" }
				}).then((response) => {
					setUser(response.data);
					if(response.data.role_id === 2) {
						setPlanExpire(response.data.business_detail.plan_expire);
						setIsProPlan(response.data.active_plan);

					}
					setIsRequestCall(true);
				});

			} catch (err) {
				console.log(err);
			}
		}
	}, [isRequestCall, user]);

   return (
		<>
			<div className="public__header" id="public__header agency_list_hash">
				<Container fluid="xl">
				<div className="logo-section" >
					<Link href={process.env.NEXT_PUBLIC_APP_URL + '/'} >
						<a style={{position: 'relative'}}>
							<Image src={Publiclogo} alt="logo" layout="fill" style={{ backgroundImage: `url(${Publiclogo.src})` }} />
						</a>
					</Link>
				</div>  
				<div className="menu-section" id="menu-section">
					<Navbar collapseOnSelect expand="lg" className="left_navbar" onToggle={onChangeNavExpanded} expanded={navExpended}>
						<Navbar.Toggle aria-controls="responsive-navbar-nav" id="toggle_nav"/>
						<Navbar.Collapse id="responsive-navbar-nav">
							<Navbar.Toggle aria-controls="responsive-navbar-nav" className="close-btn"/>
							<Nav activeKey="">
								<li key="1">
									<a href={process.env.NEXT_PUBLIC_APP_URL + "/categories/"} className={router.pathname == '/categories/' ? 'nav-link active' : 'nav-link'}
										onClick={() => {onChangeLinkExpand('/categories')}}>Categories</a>
								</li>
								<li key="2">
									<a href={process.env.NEXT_PUBLIC_APP_URL + "/write-review/"} className={router.pathname == '/write-review/' ? 'nav-link active' : 'nav-link'}
											onClick={() => {onChangeLinkExpand('/write-review')}}>Write a review</a>
								</li>
								<li key="3">
									<a href={process.env.NEXT_PUBLIC_APP_URL + "/about/"} className={router.pathname == '/about/' ? 'nav-link active' : 'nav-link'}
											onClick={() => {onChangeLinkExpand('/about')}}>About us</a>
								</li>
								<li key="4">
									<a href={process.env.NEXT_PUBLIC_APP_URL + "/blogs/"} className={router.pathname == '/blogs/' ? 'nav-link active' : 'nav-link'}
											onClick={() => {onChangeLinkExpand('/blogs')}}>Blogs</a>
								</li>
							</Nav>
						</Navbar.Collapse>
					</Navbar>
					<div className="right_navbar">
						<Nav activeKey="">
							<a href={process.env.NEXT_PUBLIC_APP_URL + '/search/'} className="nav-link search-btn"><i className="fal fa-search"></i></a>
						{ token 
							?
							<>
								<a href={dashboard} className="nav-link signin-link"><span>Dashboard</span><i className="fas fa-user"></i></a>
							</>
							:
							<>
							<Dropdown>
								<Dropdown.Toggle id="dropdown-basic">
									<span>Sign up</span>
									<i className="fas fa-user-plus"></i>
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item href={process.env.NEXT_PUBLIC_APP_URL + "/agency/signup/"}>As an Agency</Dropdown.Item>
									<Dropdown.Item href={process.env.NEXT_PUBLIC_APP_URL + "/signup/"}>As a Customer</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
								<a href={process.env.NEXT_PUBLIC_APP_URL + "/login/" }className="nav-link signin-link" style={{'padding': '13px 0px 13px 0px'}}><span>Login</span>
								<i className="fas fa-user"></i></a>
							</>
						}

						{token && userRole && userRole == 2 && (planExpire && isProPlan == 'pro')
								?
								<a href={process.env.NEXT_PUBLIC_APP_URL + "/joinaspro/"} className="nav-link primary-btn"><span>Join as pro</span>
								<i className="fas fa-user-plus"></i></a>
							 : ''}
						</Nav>
					</div>
				</div>
				</Container>
			</div>
		</>
	);
}

export default Home;